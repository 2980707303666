<template>
  <div>
    <a-modal
      :visible.sync="show"
      width="600px"
      title="礼品卡详情"
      :footer="null"
      @cancel="$emit('update:show', false)"
    >
        <a-form-model v-if="info"
          layout="horizontal"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="购买者">
                用户uid：{{info.user_id}}
                <a-button class="ml-2" type="primary" size="small" @click="handlerMember()">查看购买者</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="收款的财务账户">
                {{info.financial_account_name}}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="收款的微信子商户号">
                {{info.weixin_merchant_id}}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="流水号/订单编号">
                {{info.order_no}}
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="微信流水号">
                {{info.pay_serial_no}}
              </a-form-model-item>
            </a-col>
          </a-row>

        </a-form-model>

    </a-modal>

  </div>
</template>

<script>
// import {
//   getGiftInvoiceDetail,
// } from "@/api/invoice/gift-invoice.js"
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    }
  },
  mounted() {
  },
  methods: {
    handlerMember(){
      const newPage = this.$router.resolve({
        name: "memberDetail",
        query: { 
          member_id: this.info.user_id
        },
      });
      window.open(newPage.href, "_blank");
    },

  },
}
</script>

<style lang="less" scoped>
.preview-class /deep/ .ant-modal-body {
  padding: 10px;
}

.header {
  display: flex;
  margin-bottom: 10px;
  h4,span{
    margin-right:30px;
  }
}

.footer {
  margin-top: 20px;
  .img-class {
    width: 100px;
    height: 75px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>
