
import {
  formatOrderSource,
} from "@/utils/type";

export const columns = [
  {
    title: "ID",
    align: "center",
    dataIndex: "member_id",
    width: 90,
  },
  {
    title: "昵称",
    align: "center",
    dataIndex: "nickname",
    width: "15%",
    slots: {
      customRender: "nickname",
    },
  },
  {
    title: "绑定手机",
    align: "center",
    dataIndex: "phone",
    width: 140,
  },
  {
    title: "钱包编号",
    align: "center",
    dataIndex: "card_no",
    width: 140,
  },
  {
    title: "钱包开通地",
    align: "center",
    dataIndex: "open_region",
  },
  {
    title: "钱包开通时间",
    align: "center",
    dataIndex: "open_card_time",
    width: "19%",
  },
  {
    title: "积分",
    align: "center",
    dataIndex: "integral",
    width: "7%",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 70,
    slots: {
      customRender: "operation",
    },
  },
];

export const vipCardColumnsList = [
  {
    title: "变更类型",
    dataIndex: "change_type",
    align: "center",
  },
  {
    title: "变更时间",
    dataIndex: "change_time",
    align: "center",
  },
  {
    title: "订单交付方",
    dataIndex: "owner",
    align: "center",
  },
  {
    title: "变更金额",
    dataIndex: "recharge_amount",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    slots: {
      customRender: "operation",
    },
  },
]

export const orderColumnsList = [
  {
    title: "订单ID",
    dataIndex: "order_no",
    align: "center",
    width: 170,
    slots: { customRender: "order_no" },
  },
  {
    title: "订单来源",
    dataIndex: "order_source",
    align: "center",
    width: "12%",
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatOrderSource(val),
  },
  {
    title: "下单时间",
    dataIndex: "order_add_time",
    align: "center",
    width: "17%",
  },
  {
    title: "自提/收货时间",
    dataIndex: "time",
    align: "center",
    width: "20%",
    // slots: {
    //   customRender: "time",
    // },
  },
  {
    title: "完成时间",
    dataIndex: "order_finish_time",
    align: "center",
    width: "17%",
  },
  {
    title: "负责网点",
    dataIndex: "actual_sale_ascription_outlets",
    align: "center",
    width: "14%",
  },
  {
    title: "订单状态",
    dataIndex: "order_status",
    align: "center",
    width: 80,
    slots: {
      customRender: "order_status",
    },
  },
  {
    title: "调度人",
    dataIndex: "dispatch_staff",
    align: "center",
    width: "12%",
  }
]

export const couponColumnsList = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
    width: 70,
  },
  {
    title: "优惠券",
    dataIndex: "coupon_name",
    align: "center",
    // width: 80,
  },
  {
    title: "领取时间",
    dataIndex: "received_time",
    align: "center",
  },
  {
    title: "使用时间",
    dataIndex: "use_time",
    align: "center",
    // width: 80,
  },
]

export const giftColumnsList = [
  {
    title: "收款财务账户",
    dataIndex: "financial_account_name",
    align: "center",
    width: "20%",
  },
  {
    title: "金额",
    dataIndex: "order_amount",
    align: "center",
    width: 80,
  },
  {
    title: "购买时间",
    dataIndex: "pay_time",
    align: "center",
    width: "20%",
  },
  {
    title: "朋友领取时间",
    dataIndex: "receive_time",
    align: "center",
    width: "20%",
  },
  {
    title: "退款时间",
    dataIndex: "refund_time",
    align: "center",
    width: "20%",
  },
  {
    title: "状态",
    dataIndex: "order_status",
    align: "center",
    width: 80,
    slots: {
      customRender: "order_status",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGiftOrderStatus(val),
  },
  {
    title: "是否删除",
    dataIndex: "status",
    align: "center",
    width: 80,
    slots: {
      customRender: "status",
    },
    slotsType: "format",
    slotsFunc: (val) => {
      return val == -1 ? "已删除" : "——"
    },
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 70,
    slots: { customRender: "operation" },
  },
];

// 订单状态
export const giftOrderStatus = {
  1: "全部已支付",
  3: "待领取",
  4: "已领取",
  5: "已退款",
  2: "未支付",
};

export function formatGiftOrderStatus(val) {
  return giftOrderStatus[val];
}

const integralColumnsList = [
  {
    title: "变更时间",
    dataIndex: "add_time",
    align: "center",
    width: 180,
  },
  {
    title: "积分变更",
    dataIndex: "integral",
    align: "center",
    width: 80,
  },
  {
    title: "类型",
    dataIndex: "integral_type",
    align: "center",
    width: 90,
  },
  {
    title: "变更原因",
    dataIndex: "subtitle",
    align: "center",
    width: "40%",
  },
  {
    title: "备注",
    dataIndex: "remarks",
    align: "center",
    width: "60%",
  },
  {
    title: "操作人",
    dataIndex: "add_user",
    align: "center",
    width: 110,
  },
]

const storecardColumnsList = [
  {
    title: "封面",
    dataIndex: "cover_url",
    align: "center",
    width: 100,
    slots: { customRender: 'cover_url' }
  },
  {
    title: "实体卡号",
    dataIndex: "card_no",
    align: "center",
  },
  {
    title: "卡余额",
    dataIndex: "balance",
    align: "center",
  },
  // {
  //   title: "操作",
  //   dataIndex: "operation",
  //   align: "center",
  //   width: 90,
  //   slots: { customRender: "operation" },
  // },
]


const addressColumnsList = [
  {
    title: "收货人",
    dataIndex: "name",
    align: "center",
    width: 90,
  },
  {
    title: "电话",
    dataIndex: "phone",
    align: "center",
    width: 120,
  },
  {
    title: "地址",
    dataIndex: "address",
    align: "center",
    slots: {
      customRender: "address",
    },
    slotsType: "all",
    slotsFunc: (val) => {
      return val['province_name']+val["city_name"]+val["area_name"]+val["street_name"]+" "+val['address']+" "+val['house_number']
    },
  },
  {
    title: "adcode",
    dataIndex: "street_adcode",
    align: "center",
    width: 110,
  },
  {
    title: "经纬度",
    dataIndex: "lat_lng",
    align: "center",
    width: 120,
  },
]



export default {
  columns,

  vipCardColumnsList,
  orderColumnsList,
  couponColumnsList,
  giftColumnsList,
  integralColumnsList,
  storecardColumnsList,
  addressColumnsList
};
