<template>
  <a-modal
    :visible.sync="show"
    title="用户积分增减"
    destroyOnClose
    width="500px"
    @cancel="handlerCancel"
    :footer="null"
  >
  <div>
    <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
      <a-tab-pane key="add" tab="添加积分"></a-tab-pane>
      <a-tab-pane key="reduce" tab="扣减积分"></a-tab-pane>
    </a-tabs>
  </div>
  <div class="justify-center mt-7">
    <div style="width:100%;">
      <!-- 添加 -->
      <template v-if="activeKey == 'add'">
        <div>
          <a-row>
            <a-col :span="24">
              <a-form-model-item class="mb-2" :labelCol="{span:5}" :wrapperCol="{span:19}" label="添加积分数">
                <a-input-number v-model="formData_add.integral" :step="1" :min=0 />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item class="mb-2" :labelCol="{span:5}" :wrapperCol="{span:19}" label="添加原因">
                <a-input v-model="formData_add.subtitle" placeholder="6个字以内的简短说明（用户可见）" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="备注">
                <a-textarea v-model="formData_add.remarks"
                    :auto-size="{ minRows: 1, maxRows: 3 }" placeholder="备注说明（顾客不可见）"></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
        <div class="mt-5 mb-5 flex justify-center">
          <a-button type="primary" @click="handlerAdd">确定添加</a-button>
        </div>
      </template>
        
      <!-- 扣减 -->
      <template v-if="activeKey == 'reduce'">
        <div>
          <a-row>
            <a-col :span="24">
              <a-form-model-item class="mb-2" :labelCol="{span:5}" :wrapperCol="{span:19}" label="扣减积分数">
                <a-input-number v-model="formData_reduce.integral" :step="1" :min=0 />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item class="mb-2" :labelCol="{span:5}" :wrapperCol="{span:19}" label="扣减原因">
                <a-input v-model="formData_reduce.subtitle" placeholder="6个字以内的简短说明（用户可见）" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="备注">
                <a-textarea v-model="formData_reduce.remarks"
                    :auto-size="{ minRows: 1, maxRows: 3 }" placeholder="备注说明（顾客不可见）"></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
        <div class="mt-5 mb-5 flex justify-center">
          <a-button type="primary" @click="handlerReduce">确定扣减</a-button>
        </div>
      </template>
    </div>
  </div>
    
  </a-modal>
</template>

<script>
import {
  addIntegral
} from "@/api/vip"
import {
  reduceIntegral
} from "@/api/vip"

export default {
  components: {  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    memberId: {
      type: [ Number,String ],
      default: '',
    },
  },
  data() {
    return {
      activeKey: "add",

      formData: {
        integral: 0,
        subtitle: '',
        remarks: '',
      },

      formData_add: {
        integral: 0,
        subtitle: '',
        remarks: '',
      },
      formData_reduce: {
        integral: 0,
        subtitle: '',
        remarks: '',
      },

      isLoading: false,
    }
  },
  mounted() {

  },
  methods: {
    handlerTypeChange(val){
      this.activeKey = val
    },
    handlerAdd() {
      if(this.isLoading) return

      this.formData_add.integral = parseInt(this.formData_add.integral)
      if(! this.formData_add.integral > 0){
        this.$message.info("请填写正确的积分数量")
        return
      }
      if(!this.formData_add.subtitle || !this.formData_add.remarks){
        this.$message.info("请完善信息")
        return
      }
      const params = {...this.formData_add}
            params.member_id = this.memberId
            
      let self = this
      this.$confirm({
        title: "确认添加 " + this.formData_add.integral + " 积分？",
        async onOk() {
          self.isLoading = true
          const { code } = await addIntegral(params);
          if (code == 0) {
            self.$message.success("已添加")
            self.$emit('added')
            self.$emit('update:show', false)
          }
          self.isLoading = false
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    handlerReduce() {
      if(this.isLoading) return

      this.formData_reduce.integral = parseInt(this.formData_reduce.integral)
      if(! this.formData_reduce.integral > 0){
        this.$message.info("请填写正确的积分数量")
        return
      }
      if(!this.formData_reduce.subtitle || !this.formData_reduce.remarks){
        this.$message.info("请完善信息")
        return
      }
      const params = {...this.formData_reduce}
            params.member_id = this.memberId
            
      let self = this
      this.$confirm({
        title: "确认扣减 " + this.formData_reduce.integral + " 积分？",
        async onOk() {
          self.isLoading = true
          const { code } = await reduceIntegral(params);
          if (code == 0) {
            self.$message.success("已扣减")
            self.$emit('added')
            self.$emit('update:show', false)
          }
          self.isLoading = false
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },

    handlerCancel(){
      this.$emit('update:show', false)
    }
  }
}
</script>

<style>
</style>