<template>
  <a-modal
    :visible.sync="show"
    title="注销用户账号"
    destroyOnClose
    width="460px"
    @cancel="handlerCancel"
    :footer="null"
  >
    <div class="justify-center">
      <div style="width: 100%">
        <div>
          <a-row class="mb-2">
            <a-col :span="24">
              <a-form-model-item
                :labelCol="{ span: 12 }"
                :wrapperCol="{ span: 12 }"
                label="敏感操作，请输入您的密码"
              >
                <a-input type="password"
                   placeholder="请输入您的密码"
                   v-model="staff_password" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
        <div class="mt-5 mb-5 flex justify-center">
          <a-button :loading="isLoading" type="danger" @click="handlerOK">确定注销用户</a-button>
        </div>
        <a-divider />
        <div class="mb-2" style="color:#999;">
          <div>* 注销后，用户账户不可恢复。</div>
          <div>* 如用户再次使用手机号/微信登录、会生成一个新的空白账户。</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>

import {
  closeAccount
} from "@/api/vip.js";

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    memberId: {
      type: [ Number,String ],
      default: '',
    },
    date: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      staff_password: "",

      isLoading: false,
    };
  },
  mounted() {

  },
  methods: {
    async handlerOK() {
      if(!this.staff_password){
        this.$message.info("请输入密码");
        return
      }
      this.isLoading = true
      const {code} = await closeAccount({
        member_id: this.memberId,
        staff_password: this.staff_password
      })
      this.isLoading = false
      if(code == 0){
        this.$message.success("已注销");
        this.$emit("ok");
        this.$emit("update:show", false);
      }
    },

    handlerCancel() {
      this.$emit("update:show", false);
    },
  },
};
</script>

<style>
</style>