<template>
  <a-modal
    :visible.sync="show"
    title="拉黑下单用户"
    destroyOnClose
    width="500px"
    @cancel="handlerCancel"
    :footer="null"
  >
  <div class="justify-center">
    <div style="width:100%;">
      <div>
        <a-row class="mb-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="拉黑备注" style="margin-bottom:0;">
              <a-textarea v-model="remarks"
                :auto-size="{ minRows: 1, maxRows: 8 }"></a-textarea>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>
      
      <div class="mt-5 mb-5 flex justify-center">
        <a-button type="primary" @click="handlerAdd">确定</a-button>
      </div>
    </div>
  </div>
    
  </a-modal>
</template>

<script>
import {
  putInUserBlack
} from "@/api/vip"

export default {
  components: {  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    memberId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      remarks: "",
    }
  },
  mounted() {

  },
  methods: {
    handlerAdd() {
      let self = this
      putInUserBlack({
        member_id: self.memberId,
        blacklist_remarks: self.remarks
      }).then(res => {
        if (res.code === 0) {
          self.$message.success("已拉黑")
          self.$emit('added', {
            remarks: self.remarks
          })
        }
      })
    },

    handlerCancel(){
      this.$emit('update:show', false)
    }
  }
}
</script>

<style>
</style>