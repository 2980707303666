<template>
  <a-modal
    :visible.sync="show"
    title="修改生日"
    destroyOnClose
    width="500px"
    @cancel="handlerCancel"
    :footer="null"
  >
    <div class="justify-center">
      <div style="width: 100%">
        <div>
          <a-row class="mb-2">
            <a-col :span="24">
              <a-form-model-item
                :labelCol="{ span: 5 }"
                :wrapperCol="{ span: 19 }"
                label="设置用户生日"
              >
                <a-date-picker
                  style="width: 100%"
                  placeholder="选择日期"
                  value-format="YYYY-MM-DD"
                  v-model="newDate"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
        
        <div class="mt-5 mb-5 flex justify-center">
          <a-button type="primary" @click="handlerOK">确定</a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>

import {
  updateMember
} from "@/api/vip.js";

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    memberId: {
      type: [ Number,String ],
      default: '',
    },
    date: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      newDate: undefined
    };
  },
  mounted() {
    if(this.date){
      this.newDate = this.date
    }
  },
  methods: {
    async handlerOK() {
      if(!this.newDate) return
      if(this.newDate == this.date){
        this.$message.info("生日没修改");
        return
      }

      const {code} = await updateMember({
        member_id: this.memberId,
        birthday: this.newDate
      })
      if(code == 0){
        this.$message.success("修改成功");
        this.$emit("ok");
        this.$emit("update:show", false);
      }
    },

    handlerCancel() {
      this.$emit("update:show", false);
    },
  },
};
</script>

<style>
</style>