<template>
  <a-modal
    :visible.sync="show"
    title="修改手机号"
    destroyOnClose
    width="500px"
    @cancel="handlerCancel"
    :footer="null"
  >
    <div class="justify-center">
      <div style="width: 100%">
        <div>
          <a-row class="mb-2">
            <a-col :span="24">
              <a-form-model-item
                :labelCol="{ span: 8 }"
                :wrapperCol="{ span: 16 }"
                label="设置用户手机号"
              >
                <a-input v-model="new_phone" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
        
        <div class="mt-5 mb-5 flex justify-center">
          <a-button type="primary" @click="handlerOK">确定</a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>

import {
  updateMemberPhone
} from "@/api/vip.js";

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    memberId: {
      type: [ Number,String ],
      default: '',
    },
  },
  data() {
    return {
      new_phone: undefined
    };
  },
  mounted() {

  },
  methods: {
    async handlerOK() {
      if(!this.new_phone) return

      const {code} = await updateMemberPhone({
        member_id: this.memberId,
        new_phone: this.new_phone
      })
      if(code == 0){
        this.$message.success("修改成功");
        this.$emit("ok");
        this.$emit("update:show", false);
      }
    },

    handlerCancel() {
      this.$emit("update:show", false);
    },
  },
};
</script>

<style>
</style>