
import ajax from '@/utils/ajax.js'

/**
 * 礼品卡 订单列表
 * @param {*} params
 * @returns
 */
export function getGiftCardOrderList(params) {
  return ajax.post("/VipCardRechargeCardOrder/getList", params);
}

